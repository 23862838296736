import type React from 'react'
import { useEffect, useRef, useState } from 'react'

type InputPasswordProps = {
  value: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
  placeholder?: string
  tabIndex?: number
}

const InputPassword: React.FC<InputPasswordProps> = ({
  value,
  name,
  onChange,
  className,
  placeholder,
  tabIndex = 0,
}) => {
  const [inputType, setInputType] = useState<'password' | 'text'>('password')
  const toggleInputType = () => {
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  const [isKeyboardFocus, setIsKeyboardFocus] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputHeight, setInputHeight] = useState<number | null>(null)
  const [inputWidth, setInputWidth] = useState<number | null>(null)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setIsKeyboardFocus(true)
      }
    }

    const handleMouseDown = () => {
      setIsKeyboardFocus(false)
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      setInputHeight(inputRef.current.offsetHeight)
      setInputWidth(inputRef.current.offsetWidth)
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '')
    onChange({
      ...event,
      target: {
        ...event.target,
        value: sanitizedValue,
      },
    })
  }

  return (
    <div
      className='relative w-full flex items-stretch'
      style={{ width: `${inputWidth}px` || 'auto' }}
    >
      <input
        ref={inputRef}
        type={inputType}
        value={value}
        name={name}
        onChange={handleInputChange}
        className={`flex-1 pr-10 ${className}`}
        placeholder={
          placeholder ? `${placeholder} (半角英数文字)` : '半角英数文字'
        }
        autoComplete='off'
      />
      <button
        type='button'
        onClick={toggleInputType}
        className={`absolute top-[50%] right-[1px] translate-y-[-50%] z-10 flex justify-center items-center w-[39px] text-[16px] border-t-0 border-b-0 border-l border-r-0 border-solid border-gray-4 rounded-r-lg bg-transparent hover:bg-gray-6 icon-before icon-regular ${
          inputType === 'password' ? 'icon-eye' : 'icon-eye-slash'
        } ${isKeyboardFocus ? 'focus-keyboard' : ''}`}
        style={{
          height: inputHeight ? `calc(${inputHeight}px - 2 * 1px)` : 'auto',
        }}
        tabIndex={tabIndex}
      />
    </div>
  )
}

export default InputPassword
